import React from 'react';
import styled, { keyframes } from 'styled-components';

const spinnerOpts = {
  offset: 187,
  duration: 1.4,
};

const kfRotator = keyframes`
  0% {
        transform: rotate(0deg);
      }
  100% {
    transform: rotate(270deg);
  }
`;

const kfDash = keyframes`
  0% {
        stroke-dashoffset: ${spinnerOpts.offset};
      }
  50% {
    stroke-dashoffset: ${spinnerOpts.offset / 4};
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: ${spinnerOpts.offset};
    transform: rotate(450deg);
  }
`;

const kfColors = keyframes`
  0% {
    stroke: #4285f4;
  }
  25% {
    stroke: #de3e35;
  }
  50% {
    stroke: #f7c223;
  }
  75% {
    stroke: #1b9a59;
  }
  100% {
    stroke: #4285f4;
  }
`;

/**
 * Reference: https://codepen.io/mrrocks/pen/EiplA
 */
const StyledSpinner = styled.div`
  svg {
    animation: ${kfRotator} ${spinnerOpts.duration}s linear infinite;
    display: block;
    margin: 100px auto 0;
  }

  .path {
    stroke-dasharray: ${spinnerOpts.offset};
    stroke-dashoffset: 0;
    transform-origin: center;
    animation: ${kfDash} ${spinnerOpts.duration}s ease-in-out infinite;
    stroke: rgba(255,255,255,0.1);
    
      /* animation: ${kfDash} ${spinnerOpts.duration}s ease-in-out infinite,
      ${kfColors} ${spinnerOpts.duration * 4}s ease-in-out infinite; */
  }
`;

const LoadingSpinner = () => {
  return (
    <StyledSpinner>
      <svg width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
        <circle
          className="path"
          fill="none"
          strokeWidth="6"
          strokeLinecap="round"
          cx="33"
          cy="33"
          r="30"
        ></circle>
      </svg>
    </StyledSpinner>
  );
};

export default LoadingSpinner;
