import React from 'react';
import styled from 'styled-components';

import Logo1 from '../img/1280px-Logo_Asklepios_Kliniken_Hamburg.svg.png';
import Logo2 from '../img/Logo-teamaltona-RGB_weiss.png';
import { IAppState } from '../App';
import { BREAKPOINT } from '../constants';

const StyledTitleBar = styled.div`
  display: flex;
  min-height: 120px;
  justify-content: space-between;
  flex: 1 1;
  /* 400px is currently the maxiumum width of the sidebar, this ensure that the following flex element (Video Chapters) will line-break */
  min-width: calc(100% - 400px);
`;

const StyledTitle = styled.div`
  align-self: flex-end;
  padding: 20px;

  h1 {
    font-size: 25px;
    margin: 0;
  }
`;

const StyledLogo = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: flex-end;
  padding: 20px 0 20px 20px;
  margin-bottom: 40px;
  flex-basis: 300px;

  @media (max-width: ${BREAKPOINT.large}) {
    padding-right: 20px;
    transform: scale(0.8);
    transform-origin: bottom right;
  }

  .img-wrapper-1 {
    width: 250px;
  }

  .img-wrapper-2 {
    width: 150px;
  }

  img {
    width: 100%;
    height: auto;
  }
`;

interface IProps extends IAppState {}

const TitleBar = (props: IProps) => {
  const isIframe = window.self !== window.top;
  const isNarrow = window.innerWidth < 900;

  return (
    <StyledTitleBar>
      <StyledTitle>
        <h2 style={{ color: 'orange', marginBottom: '5px' }}>
          Displayed version: {isIframe ? 'IFRAME' : 'STANDALONE'}
          <br />
          {isIframe ? 'Iframe' : 'Viewport'} Width: {window.innerWidth}
        </h2>
        {isIframe && (
          <>
            {isIframe && (
              <p>
                <span style={{ color: 'orange' }}>!!</span> (Window width not determinable)
              </p>
            )}

            <a
              style={{ color: 'yellow', display: 'block', fontSize: '18px', marginBottom: '15px' }}
              href={window.location.href}
              target="_parent"
            >
              Go to Standalone Version
            </a>
          </>
        )}

        <h1>{props.data?.title}</h1>
      </StyledTitle>

      <StyledLogo>
        {isIframe && isNarrow ? (
          <h1 style={{ color: 'yellow' }}>"Mobile" Variant</h1>
        ) : (
          <>
            <div className="img-wrapper-1">
              <img src={Logo1} alt="Asklepios Kliniken Hamburg" />
            </div>
            <div className="img-wrapper-2">
              <img src={Logo2} alt="Team Altona" />
            </div>
          </>
        )}
      </StyledLogo>
    </StyledTitleBar>
  );
};

export default TitleBar;
