export const timeCodeToSec = (tc: string) => {
  const numArray = tc.split(':').map((el) => parseInt(el));
  return numArray.reduce((acc, time) => 60 * acc + time);
};

export const calcTimeDifference = (firstTcSec: number, secondTcSec: number) => {
  return secondTcSec - firstTcSec;
};

export const timeSecToMins = (sec: number) => {
  const minutes = () => {
    if (sec < 60) {
      const twoDigitsSec = sec.toLocaleString(undefined, { minimumIntegerDigits: 2 });
      return `0:${twoDigitsSec}`;
    }
    const newMin = Math.trunc(sec / 60);
    const newSec = (sec % 60).toLocaleString(undefined, { minimumIntegerDigits: 2 });
    return `${newMin}:${newSec}`;
  };
  return minutes().toString();
};

/**
 * Reference: https://codeburst.io/throttling-and-debouncing-in-javascript-b01cad5c8edf
 * @param func
 * @param delayMs
 */
export const debounce = (func: any, delayMs: number): any => {
  let inDebounce: number;
  return function (this: any) {
    const context = this;
    const args = arguments;
    clearTimeout(inDebounce);
    inDebounce = setTimeout(() => func.apply(context, args), delayMs);
  };
};
