import React from 'react';
import styled from 'styled-components';

import data from './data/data.json';
import VideoChapters from './components/VideoChapters';
import Video from './components/Video';
import { COLOR } from './constants';
import IData from './types/data';
import Search from './components/Search';
import TitleBar from './components/TitleBar';
import MediaButtons from './components/MediaButtons';
import { debounce } from './utils';
import ProgressBar from './components/ProgressBar';
import LoadingSpinner from './components/LoadingSpinner';
import Footer from './components/Footer';

console.info('%c🌻 CURRENT APP VARIANT:', 'font-weight: bold', process.env.REACT_APP_VARIANT);

const appVariant = process.env.REACT_APP_VARIANT;

const Page = styled.div`
  display: flex;
  background: ${appVariant === 'asklepios' ? COLOR.primaryAlt : '#314831'};
  color: ${COLOR.light};
  min-height: 100vh;
  flex-wrap: wrap;
  max-width: 1500px;
  margin: 0 auto;
`;

const MainContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-self: flex-start;
  padding-bottom: 30px;
`;

const Aside = styled.div<{ flex?: boolean }>`
  min-width: 300px;
  max-width: 400px;
  ${(props) => (props.flex ? `display: flex; flex-basis: 30%; flex-wrap: wrap` : `width: 30%`)}
`;

const VideoFrame = styled.div`
  flex-grow: 1;
`;

const FullscreenButton = styled.button`
  display: block;
  border: 1px solid #fff;
  background: rgba(0, 0, 0, 0.1);
  font-size: 18px;
  color: #fff;
  margin: 10px 0 0 10px;
  cursor: pointer;
`;

export interface IAppState {
  player: any;
  data: IData | null;
  isPlaying: boolean;
  currentSecond: number;
  searchInput: string;
  videoHeight: number;
  viewportHeight: number;
  sideBarReady: boolean;
  hasStartedPlayback: boolean;
}

class App extends React.Component<null, IAppState> {
  state: IAppState = {
    player: null,
    data: null,
    isPlaying: false,
    currentSecond: 0,
    searchInput: '',
    videoHeight: 0,
    viewportHeight: 0,
    sideBarReady: false,
    hasStartedPlayback: false,
  };

  useVariant = 'native';
  videoElem: React.RefObject<HTMLDivElement> = React.createRef();
  videoDuration = 0;

  componentDidMount() {
    this.setState({ data: data });
    window.addEventListener('resize', this.measureVideoHeight);
    window.addEventListener('resize', debounce(this.getViewportHeight, 100));
    this.getViewportHeight();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.measureVideoHeight);
    window.removeEventListener('resize', this.getViewportHeight);
  }

  componentDidUpdate(prevProps: null, prevState: IAppState) {
    const { player } = this.state;

    if (!prevState.player && player) {
      // To measure when the player loads the first time
      this.measureVideoHeight();

      // Set video duration once (won't change over time)
      if (!this.videoDuration) {
        this.videoDuration = player.getDuration();
        this.setState({ sideBarReady: true });
      }
    }
  }

  setPlayerState = (player: any) => {
    this.setState({ player: player });
  };

  setPlayingState = (val: boolean) => {
    this.setState({ isPlaying: val });
  };

  setHasStartedPlayback = (val: boolean) => {
    this.setState({ hasStartedPlayback: val });
  };

  setCurrentSecondsState = (currentTime: number) => {
    this.setState({ currentSecond: currentTime });
  };

  handleSearchInput = (input: string) => {
    this.setState({ searchInput: input.toLowerCase() });
  };

  /**
   * We want the sidebar to be the same height as the video (which is responsive)
   */
  measureVideoHeight = () => {
    if (this.videoElem.current) {
      const { height } = this.videoElem.current.getBoundingClientRect();
      this.setState({ videoHeight: Math.round(height) });
    }
  };

  getViewportHeight = () => {
    this.setState({ viewportHeight: window.innerHeight });
  };

  triggerFullscreen = () => {
    let elem = document.querySelector('#main-content');
    if (!document.fullscreenElement) {
      elem &&
        elem.requestFullscreen().catch((err) => {
          alert(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
        });
    } else {
      document.exitFullscreen();
    }
  };

  render() {
    const { searchInput, viewportHeight, sideBarReady } = this.state;

    return (
      <Page style={{ paddingTop: viewportHeight > 1000 ? '2vh' : undefined }}>
        <MainContent id="main-content">
          <Aside flex>
            <FullscreenButton type="button" onClick={this.triggerFullscreen}>
              {document.fullscreenElement ? 'Exit Fullscreen' : '(Enter Fullscreen -- internally)'}
            </FullscreenButton>
            <Search handleSearchInput={this.handleSearchInput} searchInput={searchInput} />
          </Aside>
          <TitleBar {...this.state} />
          <Aside>
            {!sideBarReady ? (
              <LoadingSpinner />
            ) : (
              <>
                <VideoChapters
                  {...this.state}
                  setCurrentSecondsState={this.setCurrentSecondsState}
                  videoDuration={this.videoDuration}
                />
                <ProgressBar {...this.state} videoDuration={this.videoDuration} />
                <MediaButtons
                  {...this.state}
                  setCurrentSecondsState={this.setCurrentSecondsState}
                />
              </>
            )}
          </Aside>

          <VideoFrame>
            <Video
              {...this.state}
              setPlayerState={this.setPlayerState}
              setPlayingState={this.setPlayingState}
              setHasStartedPlayback={this.setHasStartedPlayback}
              targetRef={this.videoElem}
            />
          </VideoFrame>
        </MainContent>
        <Footer />
      </Page>
    );
  }
}

export default App;
