import React from 'react';
import styled from 'styled-components';

import { IAppState } from '../App';
import { ReactComponent as PlayIcon } from '../img/play_arrow-white-24dp.svg';
import { ReactComponent as PauseIcon } from '../img/pause-white-24dp.svg';
import { ReactComponent as SkipPrevious } from '../img/skip_previous-white-24dp.svg';
import { ReactComponent as SkipNext } from '../img/skip_next-white-24dp.svg';
import { timeCodeToSec } from '../utils';
import { COLOR, TRANSITION } from '../constants';

const StyledMediaButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 36px;
  margin: 0 auto;
  padding: 0 34%;
  background: ${COLOR.primary};
`;

const StyledButton = styled.button`
  border: 0;
  background: none;
  cursor: pointer;
  padding: 0;
  width: 30px;
  height: 30px;

  svg {
    width: 100%;
    height: auto;

    path {
      transition: ${TRANSITION.fill};
    }

    &:hover {
      path {
        fill: rgba(255, 255, 255, 0.7);
        transition: ${TRANSITION.fill};
      }
    }
  }
`;

interface IProps extends IAppState {
  setCurrentSecondsState: (currentTime: number) => void;
}

class MediaButtons extends React.Component<IProps> {
  playPauseVideo = () => {
    const { player, isPlaying } = this.props;
    isPlaying ? player.pauseVideo() : player.playVideo();
  };

  skipChapter = (direction: 'prev' | 'next') => {
    const { data, player, currentSecond, isPlaying, setCurrentSecondsState } = this.props;

    if (!data) return;

    const adjacentVideos = data.captions.filter((v) => {
      return direction === 'prev'
        ? timeCodeToSec(v.timeCode) < currentSecond
        : timeCodeToSec(v.timeCode) > currentSecond;
    });
    let adjacentVideo;

    adjacentVideo =
      // TODO: If length-2 gets taken (to skip to the video before directly this has to be refined: Otherwise at double-click it skips one video too much)
      direction === 'prev' ? adjacentVideos[adjacentVideos.length - 1] : adjacentVideos[0];

    if (!adjacentVideo) return;

    const adjacentVideoSec = timeCodeToSec(adjacentVideo.timeCode);
    player.seekTo(adjacentVideoSec);
    setCurrentSecondsState(adjacentVideoSec);

    if (!isPlaying) player.playVideo();
  };

  render() {
    const { isPlaying } = this.props;

    return (
      <StyledMediaButtons>
        <StyledButton onClick={() => this.skipChapter('prev')}>
          <SkipPrevious />
        </StyledButton>
        <StyledButton onClick={this.playPauseVideo}>
          {isPlaying ? <PauseIcon /> : <PlayIcon />}
        </StyledButton>
        <StyledButton onClick={() => this.skipChapter('next')}>
          <SkipNext />
        </StyledButton>
      </StyledMediaButtons>
    );
  }
}

export default MediaButtons;
