import styled from 'styled-components';
import { COLOR, TRANSITION } from '../constants';

interface IProps {
  isMain?: boolean;
  isCurrentlyPlaying?: boolean;
  onClick?: () => void;
}

export const ChapterLink = styled.li<IProps>`
  display: flex;
  justify-content: space-between;
  padding: ${(props) => (props.isMain ? '10px 15px' : '7px 15px 7px 35px')};
  cursor: pointer;
  border-bottom: ${(props) => (props.isMain ? `1px solid ${COLOR.lightAlt}` : 'unset')};
  background: ${(props) => {
    if (props.isCurrentlyPlaying) return 'rgba(255,255,255,0.9)';
    if (props.isMain) return COLOR.primary;
    return 'unset';
  }};
  color: ${(props) => (props.isCurrentlyPlaying ? COLOR.primary : 'inherit')};
  transition: ${TRANSITION.background};
  font-weight: ${(props) => (props.isMain ? 'bold' : 'unset')};
  margin-top: ${(props) => (props.isMain ? '10px' : '0')};

  &:hover {
    background: ${(props) => (props.isCurrentlyPlaying ? COLOR.light : 'rgba(0,0,0,0.4)')};
    transition: ${TRANSITION.background};
  }

  &:first-child {
    margin-top: 0;
  }

  &.is-main-chapter + &.is-main-chapter {
    margin-top: 0;
  }
`;

export const ChapterLinkName = styled.span``;

export const ChapterLinkMeta = styled.span`
  flex-shrink: 0;
  font-size: 0.8em;
  align-self: center;
  font-weight: normal;
  padding-left: 10px;
`;
