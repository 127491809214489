import React from 'react';
import styled from 'styled-components';

import { BREAKPOINT, COLOR, TRANSITION } from '../constants';

const StyledFooter = styled.div`
  position: relative;
  width: 100%;
  height: 120px;
  align-self: flex-end;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: ${COLOR.primary};
  font-weight: bold;
  font-size: 16px;
  text-align: right;
  z-index: 2;

  @media (max-width: ${BREAKPOINT.large}) {
    padding-right: 25px;
  }

  /* Creating the footer background which expands over the whole page width (not only this container) --> we still need the nested structure though to get the horizontal alignment for the footer content */
  &::before {
    content: '';
    width: 100vw;
    display: block;
    position: absolute;
    /* "Hack" to move the element outside of the parent, exactly to the left viewport border */
    left: calc(-50vw + 50%);
    bottom: 0;
    height: 120px;
    background: ${COLOR.primary};
    z-index: -1;
  }

  a {
    margin-left: 70px;
    transition: ${TRANSITION.color};

    &:hover {
      color: rgba(255, 255, 255, 0.8);
      transition: ${TRANSITION.color};
    }
  }
`;

const Footer = () => (
  /* eslint-disable jsx-a11y/anchor-is-valid */
  <StyledFooter>
    <a href="">Impressum</a>
    <a href="">Datenschutz</a>
    <a href="">Lorem Ipsum</a>
  </StyledFooter>
);

export default Footer;
