import React from 'react';
import styled from 'styled-components';

import { timeCodeToSec, calcTimeDifference, timeSecToMins } from '../utils';
import { ChapterLink, ChapterLinkName, ChapterLinkMeta } from './ChapterLink';
import { IAppState } from '../App';

const LinkGroup = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  overflow-y: scroll;
  flex: 1;
  box-shadow: inset 0 -40px 30px -45px rgba(0, 0, 0, 0.8);
`;

interface IState {
  currentChapterElemRef?: any;
}

interface IProps extends IAppState {
  setCurrentSecondsState: (currentTime: number) => void;
  videoDuration: number;
}

class VideoChapters extends React.Component<IProps, IState> {
  state = {
    currentChapterElemRef: undefined,
  };

  intervalId?: number;

  componentDidMount() {
    const { player } = this.props;
    player && this.props.setCurrentSecondsState(player.getCurrentTime());
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    const { player, isPlaying, hasStartedPlayback } = this.props;
    const { currentChapterElemRef } = this.state;

    if (isPlaying) {
      clearInterval(this.intervalId);
      this.intervalId = setInterval(() => {
        this.props.setCurrentSecondsState(player.getCurrentTime());
      }, 1000);
    } else {
      clearInterval(this.intervalId);
    }

    // Only scroll list to current chapter if video has already started,
    // otherwise scroll issue when player embedded as iframe
    if (hasStartedPlayback && prevState.currentChapterElemRef !== currentChapterElemRef) {
      this.scrollCurrentChapterIntoView(currentChapterElemRef);
    }
  }

  seekToSec = (sec: number) => {
    const { player } = this.props;
    player && player.seekTo(sec, true);
    this.props.setCurrentSecondsState(sec);
    if (!this.props.isPlaying) player.playVideo();
  };

  /**
   * Save the current Ref (DOM Elem) of the <li> which is currently playing into state
   *
   * Is used to note chapter changes and scroll <li>'s into view
   */
  setCurrentChapterElem = (elem: any) => {
    this.setState({ currentChapterElemRef: elem });
  };

  scrollCurrentChapterIntoView = (elem: any) => {
    elem.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  render() {
    const { data, currentSecond, videoHeight, videoDuration } = this.props;

    let chapterNumber = 0;

    return (
      data && (
        /* Calculation: subtracted space for MediaButtons + ProgressBar */
        <LinkGroup style={{ maxHeight: videoHeight - 36 - 3 }}>
          {data.captions.map((c, index) => {
            chapterNumber = c.parentChapter ? Math.trunc(chapterNumber + 1) : chapterNumber + 0.1;

            const tcSec = timeCodeToSec(c.timeCode);
            const tcSecNextChapter =
              index < data.captions.length - 1
                ? timeCodeToSec(data.captions[index + 1].timeCode)
                : null;
            const currentlyPlaying = tcSecNextChapter
              ? tcSec <= currentSecond && currentSecond < tcSecNextChapter
              : tcSec <= currentSecond;

            let chapterDuration;
            if (videoDuration) {
              chapterDuration = tcSecNextChapter
                ? calcTimeDifference(tcSec, tcSecNextChapter)
                : calcTimeDifference(tcSec, videoDuration);
            }

            // TODO: Logic to only apply this to the search result still tbd
            // const findCurrentParentChapter = (child: IVideoCaption) => {
            //   const allParents = data.captions.filter((i) => i.parentChapter);
            //   const earlierParents = allParents.filter((i) => {
            //     return timeCodeToSec(i.timeCode) <= timeCodeToSec(child.timeCode);
            //   });
            //   return earlierParents[earlierParents.length - 1];
            // };

            const filteredChapterTitleBySearch =
              c.parentChapter || c.captionTitle.toLowerCase().includes(this.props.searchInput);

            let chapterLinkClassNames = '';
            if (currentlyPlaying) chapterLinkClassNames += 'currently-playing ';
            if (c.parentChapter) chapterLinkClassNames += 'is-main-chapter ';

            return (
              filteredChapterTitleBySearch && (
                <ChapterLink
                  key={`${c.captionTitle}-${c.timeCode}`}
                  isMain={c.parentChapter}
                  onClick={() => this.seekToSec(tcSec)}
                  isCurrentlyPlaying={currentlyPlaying}
                  className={chapterLinkClassNames}
                  ref={currentlyPlaying ? this.setCurrentChapterElem : undefined}
                >
                  <ChapterLinkName>
                    {chapterNumber.toString().slice(0, 3)}. {c.captionTitle}
                  </ChapterLinkName>
                  <ChapterLinkMeta>
                    {chapterDuration ? `${timeSecToMins(chapterDuration)} min` : null}
                  </ChapterLinkMeta>
                </ChapterLink>
              )
            );
          })}
        </LinkGroup>
      )
    );
  }
}

export default VideoChapters;
