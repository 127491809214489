import React from 'react';
import styled from 'styled-components';
import YouTube, { YouTubeProps } from 'react-youtube';

import { IAppState } from '../App';

const VideoWrapper = styled.div`
  /* position: sticky;
  top: 0; */
  position: relative;
  padding-bottom: 56.25%; /* Aspect ratio 16:9 */
  height: 0;
  overflow: hidden;
  max-width: 100%;

  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

interface IProps extends IAppState {
  setPlayingState: (arg: boolean) => void;
  setPlayerState: (player: any) => void;
  setHasStartedPlayback: (arg: boolean) => void;
  targetRef: React.RefObject<HTMLDivElement>;
}

class Video extends React.Component<IProps> {
  onPlayerReady = (event: any) => {
    this.props.setPlayerState(event.target);
  };

  onPlayerStateChange = (event: any) => {
    const { player, setPlayingState } = this.props;

    // -1 = Unstarted, 5 = Video cued (before start)
    if (player.getPlayerState() !== (5 || -1)) {
      this.props.setHasStartedPlayback(true);
    }

    // 1 = Video is playing
    player.getPlayerState() === 1 ? setPlayingState(true) : setPlayingState(false);
  };

  render() {
    const opts: YouTubeProps['opts'] = {
      // height: '390',
      // width: '640',
      playerVars: {
        // controls: 0,
        modestbranding: 1, // only works if "color=red"
        playsinline: 1,
        rel: 0,
        color: 'white',
      },
    };

    const { data } = this.props;

    return (
      data && (
        <VideoWrapper ref={this.props.targetRef}>
          <YouTube
            videoId={data.ytVideoId}
            opts={opts}
            onReady={this.onPlayerReady}
            onStateChange={this.onPlayerStateChange}
          />
        </VideoWrapper>
      )
    );
  }
}

export default Video;
