export const COLOR = {
  primary: '#283446',
  primaryAlt: '#2d3c4e',
  primaryAlt2: '#1a2331',
  light: '#fff',
  lightAlt: '#eee',
  brandGreen: '#409160',
  brandGreenDarker: '#20683d',
};

export const BREAKPOINT = {
  large: '1530px',
};

export const TRANSITION = {
  background: 'background 200ms ease-in-out',
  color: 'color 200ms ease-in-out',
  fill: 'fill 200ms ease-in-out',
};
