import React from 'react';
import styled from 'styled-components';
import { COLOR } from '../constants';

const StyledSearch = styled.form`
  width: 100%;
  align-self: flex-end;
  padding: 20px 15px;

  input {
    width: 100%;
    border-radius: 3px;
    background: rgba(255, 255, 255, 0.2);
    padding: 10px;
    border: none;
    font-weight: bold;
    color: ${COLOR.light};
    font-size: 1em;

    &::placeholder {
      color: ${COLOR.lightAlt};
    }
  }
`;

interface IProps {
  handleSearchInput: (e: string) => void;
  searchInput: string;
}

interface IState {
  value: string;
}

class Search extends React.Component<IProps, IState> {
  state = {
    value: '',
  };

  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.props.handleSearchInput(e.target.value);
  };

  render() {
    return (
      <StyledSearch onSubmit={(e) => e.preventDefault()}>
        <input
          type="text"
          value={this.props.searchInput}
          onChange={this.handleChange}
          placeholder="Suche"
        />
      </StyledSearch>
    );
  }
}

export default Search;
