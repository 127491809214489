import React from 'react';
import styled from 'styled-components';
import { COLOR } from '../constants';
import { IAppState } from '../App';

const StyledProgressBar = styled.div`
  width: 100%;
  background: ${COLOR.primaryAlt2};
  height: 3px;
  position: relative;
`;

const CurrentProgress = styled.div<{ progress?: number }>`
  position: absolute;
  top: 0;
  left: 0;
  width: ${(props) => (props.progress ? `${props.progress * 100}%` : 0)};
  height: 100%;
  background: ${COLOR.brandGreen};
`;

interface IProps extends IAppState {
  videoDuration: number;
}

const ProgressBar = (props: IProps) => {
  const { currentSecond, videoDuration } = props;

  return (
    <StyledProgressBar>
      <CurrentProgress progress={videoDuration ? currentSecond / videoDuration : undefined} />
    </StyledProgressBar>
  );
};

export default ProgressBar;
